import { Component, input, output } from '@angular/core';
import { RoamIconComponent } from '../../roam-icon/roam-icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'button-manage',
  standalone: true,
  imports: [NgClass, RoamIconComponent],
  template: `
     <button 
      [ngClass]="{'btn': border(), 'btn-base': !border() }"
      class="{{'align-center gap-6px mr-0 btn-o-' + color()}}"
      (click)="onClick.emit()">
        <roam-icon [name]="icon()" [size]="!border() ? size() : '14'" [color]="color()!" />
        <span> {{ label() }} </span>
      </button>
  `,
  styles: ``
})
export class ButtonManageComponent {
  icon = input<string>();
  label = input<string>();
  color = input<string>('primary');
  border = input<boolean>(false);
  size = input<string>('18');
  onClick = output();
}
